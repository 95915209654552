import consumer from "./consumer"

consumer.subscriptions.create("ContactEmailsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Connected to Contact Emails Room!');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Recibi el mensaje");
    // console.log(data.message_html);

    //contacts emails headers
    changeEmailContactsHeaders(data.contact, data.email);
    //contact card moved to top
    if (data.contact != '')
      $(".email-contact-card[data-contact-id='" + data.contact + "']").remove();
    else
      $(".email-contact-card[data-email='" + data.email + "']").remove();
    $("#chat-contacts.emails").prepend(data.contact_html);

    //messages
    prepareMessageAddition(data.contact, data.phone_number);

    var commentsList = null;
    if (data.contact != '')
      commentsList = $("#list_of_comments[data-contact-id='" + data.contact + "']");
    else
      commentsList = $("#list_of_comments[data-email='" + data.email + "']");
    if (commentsList.length > 0) {
      commentsList.prepend(data.message_html);
    }

    $(".menu-option[data-message-type='emails'] .unread-messages-count").removeClass('d-none');
    $(".menu-option[data-message-type='all'] .unread-messages-count").removeClass('d-none');
  }
});
