import consumer from "./consumer"

consumer.subscriptions.create("AppearanceChannel", {
  // Called once when the subscription is created.
  // initialized() {
  //   // this.update = this.update.bind(this)
  //   console.log('initialized AppearanceChannel Room!');
  // },

  // Called when the subscription is ready for use on the server.
  connected() {
    // this.install()
    // this.update()
    console.log('Connected to AppearanceChannel!');
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    // this.uninstall()
    // console.log('disconnected from AppearanceChannel!');
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log(data);
    if (window.location.pathname == "/contact_center") {
      // if user is inside the contact center
      if (data.event == "online") {
        var online_ids = $(".avatar-group-online").data("ids");
        if ($.inArray( data.user_id, online_ids ) < 0) {
          // if online user has not been taken into account already/is not currently being shown
          if ($(".avatar-group-online > .avatar").length > 4) {
            // print + number of users online
            if ($('.avatar-count').length > 0) {
              $(".avatar-count > .avatar-title").html("+"+(parseInt($(".avatar-count > .avatar-title").html()) + 1));
            } else {
              $(".avatar-group-online").append("<div class='avatar avatar-count'><div class='avatar-title rounded-circle'>+1</div></div>");
            }
          } else {
            // PRINT partial with user avatar
            $(".avatar-group-online").append(data.html);
          }
          $(".avatar-group-online").data("ids", $.merge([data.user_id], online_ids));
        }
      } else if (data.event == "check_offline") {
        var online_ids = $(".avatar-group-online").data("ids");
        var new_online_ids = online_ids;
        var i;
        for (i = 0; i < online_ids.length; i++) {
          if ($.inArray( online_ids[i], data.user_ids ) < 0) {
            // if user shown is not on the current online users
            if ($('.online-'+ online_ids[i]).length > 0) {
              //if this user is shown with an avatar
              if ($(".avatar-count").length > 0) {
                // if there were more than 4 online users
                if (parseInt($(".avatar-count > .avatar-title").html()) > 1) {
                  // add user to avatar form
                }
              }

              $('.online-'+ online_ids[i]).remove();
            }

            if (parseInt($(".avatar-count > .avatar-title").html()) == 1) {
              // remove avatar count
              $(".avatar-count").remove();
            } else{
              // just remove one user from the count
              $(".avatar-count > .avatar-title").html("+"+(parseInt($(".avatar-count > .avatar-title").html()) - 1));
            }

            var index = new_online_ids.indexOf(online_ids[i]);
            new_online_ids.splice(index, 1);
            if (index > -1) {
              $(".avatar-group-online").data("ids", new_online_ids);
            }
          }
        }


      } else if (data.event == "changeview") {
        $(".viewer-"+data.viewer_id).remove();

        if (data.contact_id) {
          // if viewers has not been taken into account already/is not currently being shown
          if ($(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find(".avatar-group > .avatar").length > 4) {
            // print + number of users viewing
            if ($(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find('.viewer-count').length > 0) {
              $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find(".viewer-count > .avatar-title").html("+"+(parseInt($(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find(".viewer-count > .avatar-title").html()) + 1));
            } else {
              $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find(".avatar-group > .avatar").append("<div class='avatar viewer-count'><div class='avatar-title rounded-circle'>+1</div></div>");
            }
          } else {
            // PRINT partial with user avatar
            $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find(".avatar-group").append(data.html);
          }
        }

      } else if (data.event == "typing") {
        if (data.contact_id) {
          var contact_card_messages = $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find('.user-messages');
          var messages_header = $("#chat-messages[data-contact-id='" + data.contact_id + "']");
        } else {
          var contact_card_messages = $(".cc-contact-card[data-phone-number='" + data.phone_number + "']").find('.user-messages');
          var messages_header = $("#chat-messages[data-phone-number='" + data.phone_number + "']");
        }
        contact_card_messages.find('.typing').remove();
        contact_card_messages.prepend("<div class='typing'><small>"+ data.typist_name +" is typing...</small></div>");
        contact_card_messages.find('.last-message').hide();
        contact_card_messages.find('.unread-messages').hide();

        messages_header.find('.typing').remove();
        messages_header.find('.current-contact').append("<div class='typing'><small>"+ data.typist_name +" is typing...</small></div>");
        messages_header.find('.current-contact').find('.last-seen').hide();
      } else if (data.event == "stop_typing") {
        if (data.contact_id) {
          var contact_card_messages = $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find('.user-messages');
          var messages_header = $("#chat-messages[data-contact-id='" + data.contact_id + "']");
        } else {
          var contact_card_messages = $(".cc-contact-card[data-phone-number='" + data.phone_number + "']").find('.user-messages');
          var messages_header = $("#chat-messages[data-phone-number='" + data.phone_number + "']");
        }
        contact_card_messages.find('.typing').remove();
        contact_card_messages.find('.last-message').show();
        contact_card_messages.find('.unread-messages').show();

        messages_header.find('.typing').remove();
        messages_header.find('.current-contact').find('.last-seen').show();
      }

    }

    if (data.event == "read_message") {
      getTopNotifications();
      if (window.location.pathname == "/contact_center")  {
        if (data.contact_id) {
          var contact_card_messages = $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").find('.user-messages');
          contact_card_messages.find('.unread-messages-count').text("0").addClass('d-none');
        }
      }
    }
  }
})
